<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { nanoid } from 'nanoid';
import { useConfirmDialog, useTitle } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { HTTPError } from 'ky';
import { Decimal } from 'decimal.js';
import { DateTime } from 'luxon';
import { useModal } from 'vue-final-modal';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLink,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  EmptyValue,
  FontIcon,
  FormInput,
  FormLabel,
  FormTextarea,
  HelpInformation,
  InvoiceExternalStatus,
  InvoiceInternalStatus,
  InvoiceSyncStatus,
  LeaveConfirmModal,
} from '@/components';
import api from '@/services/api';
import toast from '@/services/toast';
import { calculateAmount } from '@/services/invoices';
import useLoader from '@/composables/useLoader';
import useCountriesStore from '@/store/CountriesStore';
import useTrackChanges from '@/composables/useTrackChanges';
import {
  IInvoiceCustomItemRequestBody,
  IInvoiceItemResource,
  IInvoiceRequestBody,
  IInvoiceResource,
  InvoiceInternalStatusType,
  InvoiceItemPriceType,
} from '@/types/Invoice';
import { ClientInvoiceLanguage, IClientContact, IClientFullResource } from '@/types/Client';
import { IUserListResource } from '@/types/User';
import { IProjectBillingResource, IProjectMargin, IProjectPreviewResource } from '@/types/Project';
import { IInvoiceItemTemplateResource } from '@/types/InvoiceItemTemplate';
import { IErpArticleResource } from '@/types/Article';
import { ErpType } from '@/types/Erp';
import useInvoice from '@/composables/useInvoice';
import useExpandedList from '@/composables/useExpandedList';
import usePermissions from '@/composables/usePermissions';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { IContactFullResource } from '@/types/Contact';

const props = defineProps<{
  id: number;
}>();

const router = useRouter();
const { t, n, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const submitLoader = useLoader({ useProgress: false });
const syncLoader = useLoader({ useProgress: false });
const { isRevealed, reveal, confirm, cancel } = useConfirmDialog();
const countriesStore = useCountriesStore();
const { getCountries } = countriesStore;
const { countries } = storeToRefs(countriesStore);
const { openFetchNotInvoicedModal } = useInvoice();
const { can } = usePermissions();

const invoice = ref<null | IInvoiceResource>(null);
const client = ref<null | IClientFullResource>(null);

const users = ref<IUserListResource[]>([]);
const contacts = ref<IContactFullResource[]>([]);
const projects = ref<IProjectPreviewResource[]>([]);
const invoiceItemManualTemplates = ref<IInvoiceItemTemplateResource[]>([]);
const erpArticles = ref<IErpArticleResource[]>([]);
const projectMargins = ref<{ [key: number]: IProjectMargin }>({});

const closestInvoices = reactive<{ next: null | number; prev: null | number }>({
  next: null,
  prev: null,
});

const includesInvoiceItemWithoutRequiredComment = computed(() => {
  return form.invoice_items.some(
    (invoiceItem) => invoiceItem.write_off.quantity > 0 && invoiceItem.write_off.comment === '',
  );
});

const totalPrice = computed(() => {
  return form.invoice_items
    .reduce(
      (total, invoice) => total[invoice.is_inverted_amount ? 'minus' : 'plus'](getInvoicingAmount(invoice)),
      new Decimal(0),
    )
    .toNumber();
});

const isDraft = computed(() => invoice.value?.internal_status === InvoiceInternalStatusType.DRAFT);

const isActionRequired = computed(() => invoice.value?.internal_status === InvoiceInternalStatusType.ACTION_REQUIRED);

const requestData = computed<IInvoiceRequestBody>(() => {
  return {
    client_contact_uuid: form.client_contact_uuid,
    user_uuid: form.user_uuid,
    language: form.language,
    internal_note: form.internal_note,
    other_info: form.other_info,
    invoice_text: form.invoice_text,
    invoice_items: form.invoice_items
      .filter((invoice) => !invoice.custom)
      .map((invoice) => ({
        id: invoice.id as number,
        erp_article_id: invoice.erp_article_id,
        description: invoice.description,
        discount: invoice.discount,
        quantity: invoice.quantity,
        price_per_unit: invoice.price_per_unit,
        postpone: invoice.postpone,
        write_off: invoice.write_off,
      })),
    custom_invoice_items: form.invoice_items
      .filter((invoice) => invoice.custom)
      .map((invoice) => {
        const data: IInvoiceCustomItemRequestBody = {
          ...(invoice.id ? { id: invoice.id } : {}),
          project_id: invoice.project_id,
          erp_article_id: invoice.erp_article_id,
          invoice_item_template_id: invoice.invoice_template_id ?? null,
          quantity: invoice.quantity,
          price_per_unit: invoice.price_per_unit,
          description: invoice.description,
          discount: invoice.discount,
          postpone: invoice.postpone,
          write_off: invoice.write_off,
        };
        return data;
      }),
  };
});

type InvoiceItemForm = {
  id?: number;
  uid: string;
  custom: boolean;
  project_id: null | number;
  project_name: null | string;
  invoice_template_id?: null | number;
  description: string;
  erp_article_id: null | number;
  quantity: number;
  discount: number;
  unit: null | string;
  price_per_unit: number;
  price_type: null | string;
  is_inverted_amount: boolean;
  original_quantity: number;
  postpone: {
    quantity: number;
    comment: string;
  };
  write_off: {
    quantity: number;
    comment: string;
  };
  invoiced_period: { end: string; start: string };
};

const {
  isExpanded: isExpandedInvoice,
  toggle: toggleInvoice,
  expand: expandInvoice,
  collapse: collapseInvoice,
  toggleAll: toggleAllInvoices,
  isEmpty: isExpandedInvoicesEmpty,
} = useExpandedList();

type InvoiceForm = Pick<
  IInvoiceRequestBody,
  'client_contact_uuid' | 'user_uuid' | 'language' | 'internal_note' | 'invoice_text' | 'other_info'
> & {
  invoice_items: InvoiceItemForm[];
};

const form = reactive<InvoiceForm>({
  client_contact_uuid: null,
  user_uuid: null,
  language: ClientInvoiceLanguage.SV,
  internal_note: '',
  invoice_text: '',
  other_info: '',
  invoice_items: [],
});

const tracker = useTrackChanges(form);

const country = computed(
  () =>
    countries.value.find(({ code }) => code === invoice.value?.billing_address.country)?.name[locale.value] ??
    invoice.value?.billing_address.country,
);

const discountable = computed(() => invoice.value?.system === ErpType.FORTNOX);

function getErpArticle(id: number) {
  return erpArticles.value.find((erpArticle) => erpArticle.id === id) ?? null;
}

function getInvoiceItemManualTemplate(id: number | null | undefined) {
  if (!id) return null;
  return invoiceItemManualTemplates.value.find((templateId) => templateId.id === id) ?? null;
}

const editable = computed(() => {
  if (invoice.value === null) return false;
  return [
    InvoiceInternalStatusType.DRAFT,
    InvoiceInternalStatusType.ACTION_REQUIRED,
    InvoiceInternalStatusType.UNMATCHED,
  ].includes(invoice.value.internal_status);
});

async function getInvoice() {
  try {
    const response = await api.invoices.get(props.id);
    invoice.value = response.data;
    setInvoiceForm(response.data);
    closestInvoices.next = response.next;
    closestInvoices.prev = response.prev;
  } catch (error: unknown) {
    if (error instanceof HTTPError && error.response.status === 404) {
      await router.push({ name: 'NotFound' });
      throw error;
    }
  }
}

function setInvoiceItemForm(invoiceItem: IInvoiceItemResource): InvoiceItemForm {
  return {
    id: invoiceItem.id,
    uid: nanoid(),
    custom: invoiceItem.custom,
    project_id: invoiceItem.project.id,
    project_name: invoiceItem.project.name,
    description: invoiceItem.description ?? '',
    erp_article_id: invoiceItem.erp_article?.id ?? null,
    unit: invoiceItem.erp_article?.unit ?? null,
    price_per_unit: invoiceItem.price_per_unit,
    price_type: invoiceItem.price_type,
    invoice_template_id: invoiceItem.invoice_item_template?.id ?? null,
    is_inverted_amount: invoiceItem.invoice_item_template?.is_inverted_amount ?? false,
    quantity: invoiceItem.quantity,
    discount: invoiceItem.discount,
    original_quantity: invoiceItem.original_quantity,
    postpone: {
      quantity: invoiceItem.postpone?.quantity ?? 0,
      comment: invoiceItem.postpone?.comment ?? '',
    },
    write_off: {
      quantity: invoiceItem.write_off?.quantity ?? 0,
      comment: invoiceItem.write_off?.comment ?? '',
    },
    invoiced_period: invoiceItem.invoiced_period,
  };
}

function setInvoiceForm(invoice: IInvoiceResource) {
  form.client_contact_uuid ||= invoice.clientContact?.uuid;
  form.language = invoice.language ? invoice.language : form.language;
  form.user_uuid ||= invoice.user?.uuid;
  form.internal_note = invoice.internal_note ?? '';
  form.invoice_text = invoice.invoice_text ?? '';
  form.other_info = invoice.other_info ?? '';
  form.invoice_items = invoice.invoiceItems.map(setInvoiceItemForm);
}

function addCustomItem() {
  const selectedProject = projects.value.length === 1 ? projects.value[0].id : null;
  form.invoice_items.push({
    uid: nanoid(),
    custom: true,
    project_id: selectedProject,
    project_name: null,
    invoice_template_id: null,
    description: '',
    erp_article_id: null,
    quantity: 1,
    discount: 0,
    unit: null,
    price_per_unit: 0,
    price_type: null,
    is_inverted_amount: false,
    original_quantity: 0,
    postpone: {
      quantity: 0,
      comment: '',
    },
    write_off: {
      quantity: 0,
      comment: '',
    },
    invoiced_period: { end: '', start: '' },
  });
}

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getContacts() {
  if (invoice.value === null) return;
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.clients.contacts.list(invoice.value.client.uuid, { searchParams });
    contacts.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getProjects() {
  if (invoice.value === null) return;
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    ['active', 'cancelled', 'done'].forEach((status) => {
      searchParams.append('statuses[]', status);
    });
    const response = await api.clients.projects.list(invoice.value.client.uuid, { searchParams });
    projects.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

function onFetchNotInvoiced() {
  openFetchNotInvoicedModal(
    {
      client: invoice.value!.client.uuid,
      time_period: invoice.value!.time_period.id,
    },
    {
      async onFetched() {
        const {
          data: { invoiceItems },
        } = await api.invoices.get(props.id);
        invoiceItems.forEach((invoiceItem) => {
          if (!form.invoice_items.some(({ id }) => id === invoiceItem.id)) {
            form.invoice_items.push(setInvoiceItemForm(invoiceItem));
          }
        });
      },
    },
  );
}

async function getProjectMargins() {
  if (projects.value.length === 0) return;
  try {
    const searchParams = new URLSearchParams();
    projects.value.forEach(({ id }) => {
      searchParams.append('projects[]', id.toString());
    });
    projectMargins.value = await api.projects.margin(searchParams);
  } catch (error) {
    console.error(error);
  }
}

async function getClient() {
  if (invoice.value === null) return;
  try {
    const response = await api.clients.get(invoice.value.client.uuid);
    client.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getErpArticles() {
  try {
    const response = await api.erpArticles.index();
    erpArticles.value = response.data.filter(({ status }) => status === 'enabled');
  } catch (error) {
    console.error(error);
  }
}

async function getInvoiceItemTemplates() {
  try {
    const response = await api.invoiceItemTemplates.index();
    invoiceItemManualTemplates.value = response.data
      .filter((template) => template.type === 'manual')
      .sort((a, b) => a.price_type.localeCompare(b.price_type));
  } catch (error) {
    console.error(error);
  }
}

function getPostponeAmount(invoiceItem: InvoiceItemForm) {
  return calculateAmount(invoiceItem.postpone.quantity, invoiceItem.price_per_unit, invoiceItem.discount);
}

function getWriteOffAmount(invoiceItem: InvoiceItemForm) {
  return calculateAmount(invoiceItem.write_off.quantity, invoiceItem.price_per_unit, invoiceItem.discount);
}

function getInvoicingAmount(invoiceItem: InvoiceItemForm) {
  return calculateAmount(invoiceItem.quantity, invoiceItem.price_per_unit, invoiceItem.discount);
}

async function save() {
  try {
    submitLoader.start();
    const response = await api.invoices.update(props.id, requestData.value);
    invoice.value = response.data;
    setInvoiceForm(response.data);
    await getProjectsBilling();
    tracker.commit();
    toast.success(t('common.messages.has_been_updated', { name: t('invoice.resource.name') }));
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

async function approveAndSync() {
  try {
    syncLoader.start();
    const response = await api.invoices.approve(requestData.value, props.id);
    invoice.value = response.data;
    setInvoiceForm(response.data);
    tracker.commit();
    if (closestInvoices.next) {
      await router.push({ name: 'invoices.edit', params: { id: closestInvoices.next } });
    }
  } catch (error) {
    console.error(error);
    if (error instanceof HTTPError && error.response && error.response.status === 500) {
      await getInvoice();
    }
  } finally {
    syncLoader.finish();
  }
}

function onDeleteCustomInvoiceItem(uid: string) {
  collapseInvoice(uid);
  form.invoice_items = form.invoice_items.filter((invoice) => invoice.uid !== uid);
}

function onInvoiceItemChoosePriceType(index: number) {
  const invoiceItemTemplate = invoiceItemManualTemplates.value.find(
    ({ id }) => id === form.invoice_items[index].invoice_template_id,
  );
  if (!invoiceItemTemplate) return;
  if (invoiceItemTemplate.price_type !== InvoiceItemPriceType.ADVANCE) {
    if (form.invoice_items[index].price_per_unit < 0) {
      form.invoice_items[index].price_per_unit = Math.abs(form.invoice_items[index].price_per_unit);
    }
  } else {
    form.invoice_items[index].postpone.comment = '';
    form.invoice_items[index].postpone.quantity = 0;
    form.invoice_items[index].write_off.comment = '';
    form.invoice_items[index].write_off.quantity = 0;
    collapseInvoice(form.invoice_items[index].uid);
  }
  form.invoice_items[index].is_inverted_amount = invoiceItemTemplate.is_inverted_amount;
  form.invoice_items[index].erp_article_id = invoiceItemTemplate.erp_article?.id ?? null;
  form.invoice_items[index].unit = invoiceItemTemplate.erp_article?.unit ?? null;
  setInvoiceItemDescription(index);
}

function setInvoiceItemDescription(index?: number) {
  if (index) {
    const invoiceItemTemplate = invoiceItemManualTemplates.value.find(
      ({ id }) => id === form.invoice_items[index].invoice_template_id,
    );
    if (!invoiceItemTemplate) return;
    const projectName = projects.value.find((p) => p.id === form.invoice_items[index].project_id)?.name ?? '';
    const serviceName = projects.value.find((p) => p.id === form.invoice_items[index].project_id)?.service.name ?? '';
    const timePeriodDate = DateTime.fromObject({
      day: 1,
      month: invoice.value!.time_period.month,
      year: invoice.value!.time_period.year,
    }).setLocale(form.language);
    form.invoice_items[index].description = replaceDescriptionVars(
      form.language ? invoiceItemTemplate[`description_${form.language}`] : '',
      {
        project_name: projectName,
        service_name: serviceName,
        current_month: timePeriodDate.toFormat('MMMM'),
        previous_month: timePeriodDate.minus({ months: 1 }).toFormat('MMMM'),
      },
    );
  } else {
    form.invoice_items
      .filter((invoiceItem) => invoiceItem.custom && !!invoiceItem.invoice_template_id && !!invoiceItem.project_id)
      .forEach((invoiceItem) => {
        const invoiceItemTemplate = invoiceItemManualTemplates.value.find(
          ({ id }) => id === invoiceItem.invoice_template_id,
        );
        if (!invoiceItemTemplate) return;
        const projectName = projects.value.find((p) => p.id === invoiceItem.project_id)?.name ?? '';
        const serviceName = projects.value.find((p) => p.id === invoiceItem.project_id)?.service.name ?? '';
        const timePeriodDate = DateTime.fromObject({
          day: 1,
          month: invoice.value!.time_period.month,
          year: invoice.value!.time_period.year,
        }).setLocale(form.language);
        invoiceItem.description = replaceDescriptionVars(
          form.language ? invoiceItemTemplate[`description_${form.language}`] : '',
          {
            project_name: projectName,
            service_name: serviceName,
            current_month: timePeriodDate.toFormat('MMMM'),
            previous_month: timePeriodDate.minus({ months: 1 }).toFormat('MMMM'),
          },
        );
      });
  }
}

function onInvoiceItemChooseArticle(index: number) {
  const erpArticle = erpArticles.value.find(({ id }) => id === form.invoice_items[index].erp_article_id);
  if (!erpArticle) return;
  form.invoice_items[index].unit = erpArticle.unit ?? null;
}

function isInvoiceItemAdvance(invoiceItem: InvoiceItemForm) {
  if (!invoiceItem.invoice_template_id) return;
  return (
    invoiceItemManualTemplates.value.find((i) => i.id === invoiceItem.invoice_template_id)?.price_type ===
    InvoiceItemPriceType.ADVANCE
  );
}

function onDiscountChange(invoiceItem: InvoiceItemForm, event: Event) {
  const input = event.target as HTMLInputElement;
  const discount = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();
  invoiceItem.discount = Math.min(discount, 100);
  input.value = invoiceItem.discount.toString();
}

function onQuantityChange(invoiceItem: InvoiceItemForm, event: Event) {
  const input = event.target as HTMLInputElement;
  const quantity = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();

  if (invoiceItem.custom) {
    invoiceItem.quantity = quantity;
  } else {
    if (quantity >= invoiceItem.original_quantity) {
      // Case 1
      invoiceItem.quantity = invoiceItem.original_quantity;
      invoiceItem.postpone.quantity = 0;
      invoiceItem.write_off.quantity = 0;
    } else {
      if (quantity >= invoiceItem.quantity) {
        if (invoiceItem.postpone.quantity >= quantity - invoiceItem.quantity) {
          // Case 4
          console.info('Invoicing sum: Case 4');
          invoiceItem.postpone.quantity = new Decimal(invoiceItem.postpone.quantity)
            .minus(new Decimal(quantity).minus(invoiceItem.quantity))
            .toNumber();
        } else {
          // Case 3
          console.info('Invoicing sum: Case 3');
          invoiceItem.postpone.quantity = 0;
          invoiceItem.write_off.quantity = new Decimal(invoiceItem.original_quantity).minus(quantity).toNumber();
        }
      } else {
        // Case 2
        console.info('Invoicing sum: Case 2');
        invoiceItem.postpone.quantity = new Decimal(invoiceItem.postpone.quantity)
          .plus(new Decimal(invoiceItem.quantity).minus(quantity))
          .toNumber();
      }
      invoiceItem.quantity = quantity;
    }
  }

  input.value = invoiceItem.quantity.toString();
}

function discardPostpone(invoiceItem: InvoiceItemForm) {
  invoiceItem.quantity = Decimal.add(invoiceItem.quantity, invoiceItem.postpone.quantity).toNumber();
  invoiceItem.postpone.quantity = 0;
}

function discardWriteOff(invoiceItem: InvoiceItemForm) {
  invoiceItem.quantity = Decimal.add(invoiceItem.quantity, invoiceItem.write_off.quantity).toNumber();
  invoiceItem.write_off.quantity = 0;
}

function onPostponeQuantityChange(invoiceItem: InvoiceItemForm, event: Event) {
  const input = event.target as HTMLInputElement;
  const postponeQuantity = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();

  if (invoiceItem.custom) {
    invoiceItem.postpone.quantity = postponeQuantity;
  } else {
    if (postponeQuantity >= invoiceItem.original_quantity) {
      // Case 1
      console.info('Postpone quantity: Case 1');
      invoiceItem.postpone.quantity = invoiceItem.original_quantity;
      invoiceItem.quantity = 0;
      invoiceItem.write_off.quantity = 0;
    } else {
      if (postponeQuantity >= invoiceItem.postpone.quantity) {
        if (invoiceItem.quantity >= postponeQuantity - invoiceItem.postpone.quantity) {
          // Case 4
          console.info('Postpone quantity: Case 4');
          invoiceItem.quantity = new Decimal(invoiceItem.quantity)
            .minus(new Decimal(postponeQuantity).minus(invoiceItem.postpone.quantity))
            .toNumber();
        } else {
          // Case 3
          console.info('Postpone quantity: Case 3');
          invoiceItem.quantity = 0;
          invoiceItem.write_off.quantity = new Decimal(invoiceItem.original_quantity)
            .minus(postponeQuantity)
            .toNumber();
        }
      } else {
        // Case 2
        console.info('Postpone quantity: Case 2');
        invoiceItem.quantity = new Decimal(invoiceItem.quantity)
          .plus(new Decimal(invoiceItem.postpone.quantity).minus(postponeQuantity))
          .toNumber();
      }
      invoiceItem.postpone.quantity = postponeQuantity;
    }
  }

  input.value = invoiceItem.postpone.quantity.toString();
}

function onWriteOffQuantityChange(invoiceItem: InvoiceItemForm, event: Event) {
  const input = event.target as HTMLInputElement;
  const writeOffQuantity = new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();

  if (invoiceItem.custom) {
    invoiceItem.write_off.quantity = writeOffQuantity;
  } else {
    if (writeOffQuantity >= invoiceItem.original_quantity) {
      // Case 1
      console.info('Write-off quantity: Case 1');
      invoiceItem.quantity = 0;
      invoiceItem.postpone.quantity = 0;
      invoiceItem.write_off.quantity = invoiceItem.original_quantity;
    } else {
      if (writeOffQuantity >= invoiceItem.write_off.quantity) {
        if (invoiceItem.quantity >= writeOffQuantity - invoiceItem.write_off.quantity) {
          // Case 4
          console.info('Write-off quantity: Case 4');
          invoiceItem.quantity = new Decimal(invoiceItem.quantity)
            .minus(new Decimal(writeOffQuantity).minus(invoiceItem.write_off.quantity))
            .toNumber();
        } else {
          // Case 3
          console.info('Write-off quantity: Case 3');
          invoiceItem.quantity = 0;
          invoiceItem.postpone.quantity = new Decimal(invoiceItem.original_quantity)
            .minus(new Decimal(writeOffQuantity))
            .toNumber();
        }
      } else {
        // Case 2
        console.info('Write-off quantity: Case 2');
        invoiceItem.quantity = new Decimal(invoiceItem.quantity)
          .plus(new Decimal(invoiceItem.write_off.quantity).minus(writeOffQuantity))
          .toNumber();
      }
      invoiceItem.write_off.quantity = writeOffQuantity;
    }
  }

  input.value = invoiceItem.write_off.quantity.toString();
}

function onPricePerUnitChange(invoiceItem: InvoiceItemForm, event: Event) {
  const input = event.target as HTMLInputElement;
  const pricePerUnit = isInvoiceItemAdvance(invoiceItem)
    ? new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).toNumber()
    : new Decimal(input.value ? input.value : 0).toDecimalPlaces(2).abs().toNumber();
  invoiceItem.price_per_unit = pricePerUnit;
  input.value = pricePerUnit.toString();
}

function onPostpone(invoiceItem: InvoiceItemForm) {
  invoiceItem.postpone.quantity = Decimal.add(invoiceItem.quantity, invoiceItem.postpone.quantity).toNumber();
  invoiceItem.quantity = 0;
  expandInvoice(invoiceItem.uid);
}

function onWriteOff(invoiceItem: InvoiceItemForm) {
  invoiceItem.write_off.quantity = Decimal.add(invoiceItem.quantity, invoiceItem.write_off.quantity).toNumber();
  invoiceItem.quantity = 0;
  expandInvoice(invoiceItem.uid);
}

function getOriginalQuantity(invoiceItem: InvoiceItemForm) {
  return invoiceItem.custom
    ? new Decimal(invoiceItem.quantity)
        .plus(invoiceItem.postpone.quantity)
        .plus(invoiceItem.write_off.quantity)
        .toDecimalPlaces(2)
        .toNumber()
    : invoiceItem.original_quantity;
}

function getOriginalAmount(invoiceItem: InvoiceItemForm) {
  const quantity = invoiceItem.custom
    ? new Decimal(invoiceItem.quantity).plus(invoiceItem.postpone.quantity).plus(invoiceItem.write_off.quantity)
    : new Decimal(invoiceItem.original_quantity);
  return calculateAmount(quantity.toDecimalPlaces(2).toNumber(), invoiceItem.price_per_unit, invoiceItem.discount);
}

// Prevent data loss: START
function onBeforeUnload(event: BeforeUnloadEvent) {
  if (!tracker.isModified.value) return true;
  event.preventDefault();
  const customMessage = t('common.confirms.unsaved.title');
  event.returnValue = customMessage;
  return customMessage;
}

onMounted(async () => {
  try {
    loader.start();
    await getInvoice();
    await Promise.all([
      getCountries(),
      getUsers(),
      getContacts(),
      getProjects(),
      getErpArticles(),
      getClient(),
      getInvoiceItemTemplates(),
      getProjectsBilling(),
    ]);
    await Promise.all([getProjectMargins()]);

    // Set default invoice settings from client's settings
    if (!form.other_info && client.value?.invoice_default_info) {
      form.other_info = client.value.invoice_default_info;
    }
    if (!form.language && client.value?.invoice_default_language) {
      form.language = client.value.invoice_default_language;
    }
    if (!form.client_contact_uuid && client.value?.invoice_default_contact) {
      form.client_contact_uuid = client.value.invoice_default_contact.uuid;
    }
    tracker.commit();
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
});

onMounted(() => {
  window.addEventListener('beforeunload', onBeforeUnload);
});

onUnmounted(() => {
  window.removeEventListener('beforeunload', onBeforeUnload);
});

onBeforeRouteLeave(async (_to, _from, next) => {
  if (tracker.isModified.value) {
    const { data } = await reveal();
    data ? next() : next(false);
  } else {
    next();
  }
});

function replaceDescriptionVars(template: string, vars: Record<string, string>) {
  return template.replace(/{(\w+)}/g, (_, key) => {
    return vars[key] !== undefined ? vars[key] : `{${key}}`;
  });
}

const title = useTitle(computed(() => t('invoice.edit.title', { id: invoice.value?.id })));

const projectsBilling = ref<IProjectBillingResource[]>([]);

async function getProjectsBilling() {
  if (invoice.value === null) return;
  try {
    const response = await api.clients.billing.projects(invoice.value.client.uuid);
    projectsBilling.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

const projectsWithExistedAdvanceBalance = computed(() =>
  projectsBilling.value.filter((p) => p.is_advance_payments_exists && p.advance_balance !== 0),
);

const projectsWithoutExistedAdvanceBalance = computed(() => {
  return projectsBilling.value
    .filter((p) => !p.is_advance_payments_exists)
    .filter((p) => {
      return form.invoice_items.some((i) => {
        const invoiceItemTemplate = invoiceItemManualTemplates.value.find(({ id }) => id === i.invoice_template_id);
        if (!invoiceItemTemplate) return false;
        return (
          p.id === i.project_id &&
          invoiceItemTemplate.price_type === InvoiceItemPriceType.ADVANCE &&
          i.price_per_unit < 0
        );
      });
    });
});

function showNegativeAdvanceBalanceWarning(invoiceItem: InvoiceItemForm) {
  const isAdvance = isInvoiceItemAdvance(invoiceItem);
  const invoiceItemTemplate = invoiceItemManualTemplates.value.find(({ id }) => id === invoiceItem.invoice_template_id);
  if (!invoiceItemTemplate) return false;
  const project = projectsBilling.value.find(
    ({ id, is_advance_payments_exists }) => id === invoiceItem.project_id && is_advance_payments_exists,
  );
  if (!project) return false;
  return (
    isAdvance &&
    invoiceItem.project_id === project.id &&
    invoiceItemTemplate.price_type === InvoiceItemPriceType.ADVANCE &&
    project.advance_balance + getInvoicingAmount(invoiceItem) < 0
  );
}

function onDelete() {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('invoice.confirm.delete.title'),
      message: t('invoice.confirm.delete.text'),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.invoices.destroy(props.id);
          toast.success(t('common.messages.has_been_deleted', { name: t('invoice.resource.name') }));
          await router.push({ name: 'invoices.drafts' });
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <div v-if="!loader.isLoading.value && invoice" class="container-wide">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center">
        <AppButton
          @click.prevent="router.push({ name: 'invoices.index' })"
          color="secondary"
          circle
          light
          v-tooltip="t('common.back')"
        >
          <FontIcon name="arrow-back-up" />
        </AppButton>
        <div class="d-flex align-items-end mx-3">
          <h1 class="mb-0" v-text="title" />
          <HelpInformation class="ml-1" translation="invoice.edit.help" />
        </div>
      </div>
    </div>
    <form @submit.prevent="save">
      <AppBox shadow>
        <AppBoxBody>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-12" v-text="invoice.client.name" />
              </div>
              <div class="row mt-3">
                <div class="col-12 font-bold" v-text="t('invoice.attributes.billing_adress')" />
                <div class="col-12">
                  <div v-if="invoice.billing_address.first_address" v-text="invoice.billing_address.first_address" />
                  <div v-if="invoice.billing_address.second_address" v-text="invoice.billing_address.second_address" />
                  <div v-if="invoice.billing_address.zip" v-text="invoice.billing_address.zip" />
                  <div v-if="invoice.billing_address.city" v-text="invoice.billing_address.city" />
                  <div v-if="country" v-text="country" />
                  <AppLink v-if="invoice.billing_address.email" :value="invoice.billing_address.email" mode="email" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-5 font-bold" v-text="t('invoice.attributes.internal_status')" />
                <div class="col-md-7">
                  <InvoiceInternalStatus :status="invoice.internal_status" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-5 font-bold" v-text="t('invoice.attributes.external_status')" />
                <div class="col-md-7">
                  <InvoiceExternalStatus v-if="invoice.external_status" :status="invoice.external_status" />
                  <EmptyValue v-else />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-5 font-bold" v-text="t('invoice.attributes.sync_status')" />
                <div class="col-md-7">
                  <InvoiceSyncStatus v-if="invoice.sync_status" :status="invoice.sync_status" />
                  <EmptyValue v-else />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-5">
                  <FormLabel class="font-bold" html-for="invoice_client_reference">
                    {{ t('invoice.attributes.client_reference') }}
                  </FormLabel>
                </div>
                <div class="col-md-7">
                  <VueSelect
                    :clearable="true"
                    :options="contacts"
                    :get-option-label="(option:IClientContact) => option.short_name ?? option.name"
                    v-model="form.client_contact_uuid"
                    :reduce="(option: IClientContact) => option.uuid"
                    input-id="invoice_client_reference"
                    :placeholder="t('common.select')"
                    v-if="editable"
                  >
                  </VueSelect>
                  <span v-else>
                    {{ invoice.clientContact?.name }}
                    {{ invoice.clientContact?.is_deleted ? `(${$t('common.deleted').toLowerCase()})` : '' }}
                  </span>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-5">
                  <FormLabel required class="font-bold" html-for="invoice_user">
                    {{ t('invoice.attributes.user') }}
                  </FormLabel>
                </div>
                <div class="col-md-7">
                  <VueSelect
                    :clearable="false"
                    :options="users"
                    label="name"
                    v-model="form.user_uuid"
                    :reduce="(option: IUserListResource) => option.uuid"
                    input-id="invoice_user"
                    :placeholder="t('common.select')"
                    v-if="editable"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.user_uuid"
                        v-bind="attributes as object"
                        v-on="events"
                      />
                    </template>
                  </VueSelect>
                  <span v-else v-text="invoice.user?.name" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div>
                <FormLabel class="font-bold" html-for="invoice_other_information">
                  {{ t('invoice.attributes.other_information') }}
                </FormLabel>
                <FormTextarea v-if="editable" rows="2" id="invoice_other_information" v-model="form.other_info" />
                <span v-else v-text="form.other_info" />
              </div>
              <div class="row mt-4">
                <div class="col-md-5">
                  <FormLabel required class="font-bold" html-for="invoice_invoice_language">
                    {{ t('invoice.attributes.invoice_language') }}
                  </FormLabel>
                </div>
                <div class="col-md-7">
                  <VueSelect
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="Object.values(ClientInvoiceLanguage)"
                    :get-option-label="(option: string) => t(`common.${option}`)"
                    label="label"
                    input-id="invoice_invoice_language"
                    :placeholder="t('common.select')"
                    v-model="form.language"
                    v-if="editable"
                    @update:model-value="setInvoiceItemDescription()"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.language"
                        v-bind="attributes as object"
                        v-on="events"
                      />
                    </template>
                  </VueSelect>
                  <span v-else v-text="t(`common.${form.language}`)" />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-5">
                  <FormLabel class="font-bold">
                    {{ t('client.attributes.invoice_distribution') }}
                  </FormLabel>
                </div>
                <div class="col-md-7">
                  <span
                    v-if="invoice.invoice_distribution"
                    v-text="t(`invoice.distribution.${invoice.invoice_distribution}`)"
                  />
                  <EmptyValue v-else />
                </div>
              </div>
            </div>
          </div>
          <AppAlert v-if="invoice.sync_error_message" class="mt-3" type="danger">
            {{ invoice.sync_error_message }}
          </AppAlert>
          <AppAlert v-if="invoice.internal_status === InvoiceInternalStatusType.UNMATCHED" class="mt-3" type="danger">
            {{
              t('invoice.unmatched_error', {
                external_amount: n(new Decimal(invoice.external_amount ?? 0).toDecimalPlaces(2).toNumber(), 'currency'),
              })
            }}
            ({{
              n(
                new Decimal(invoice.price ?? 0)
                  .minus(invoice.external_amount ?? 0)
                  .toDecimalPlaces(2)
                  .toNumber(),
                'currency',
              )
            }})
          </AppAlert>
        </AppBoxBody>
      </AppBox>
      <AppTable class="mt-3" hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh width="20%" nowrap>{{ t('invoice.details.project') }}</AppTableTh>
            <AppTableTh nowrap>
              {{ t('invoice.details.estimated_margin_percent') }}
            </AppTableTh>
            <AppTableTh width="10%" nowrap>{{ t('invoice.details.price_type') }}</AppTableTh>
            <AppTableTh width="40%" nowrap>{{ t('invoice.details.description') }}</AppTableTh>
            <AppTableTh nowrap />
            <AppTableTh width="20%" nowrap>{{ t('invoice.details.article') }}</AppTableTh>
            <AppTableTh width="8%" class="text-right" nowrap>{{ t('invoice.details.qty') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('invoice.details.unit') }}</AppTableTh>
            <AppTableTh class="text-right" nowrap v-if="discountable">
              {{ t('invoice.details.discount') }}
            </AppTableTh>
            <AppTableTh class="text-right" nowrap>{{ t('invoice.details.price_unit') }}</AppTableTh>
            <AppTableTh class="text-right" nowrap>{{ t('invoice.details.amount') }}</AppTableTh>
            <AppTableTh class="text-right" nowrap>
              <AppButton
                v-if="form.invoice_items.length > 0"
                @click.stop.prevent="toggleAllInvoices(form.invoice_items.map((item) => item.uid))"
                light
                circle
                size="small"
                v-tooltip="t(`invoice.tooltip.${isExpandedInvoicesEmpty ? 'minimize_all' : 'expand_all'}`)"
                :disabled="includesInvoiceItemWithoutRequiredComment"
              >
                <FontIcon :name="isExpandedInvoicesEmpty ? 'chevrons-up' : 'chevrons-down'" />
              </AppButton>
            </AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <!-- Invoices -->
          <template v-for="(invoiceItem, index) in form.invoice_items" :key="invoiceItem.uid">
            <AppTableTr style="border-top-width: 5px">
              <!-- Project -->
              <AppTableTd nowrap>
                <div v-if="invoiceItem.custom" class="form-wrapper is-small">
                  <select
                    @change="setInvoiceItemDescription(index)"
                    class="form-control"
                    v-model="invoiceItem.project_id"
                    required
                  >
                    <option :value="null" selected disabled hidden v-text="t('common.select')" />
                    <option v-for="option in projects" :key="option.id" :value="option.id" v-text="option.name" />
                  </select>
                </div>
                <span v-else v-text="invoiceItem.project_name" />
              </AppTableTd>
              <!-- Marginality -->
              <AppTableTd nowrap>
                <span
                  v-if="invoiceItem.project_id && projectMargins[invoiceItem.project_id]"
                  v-text="projectMargins[invoiceItem.project_id].marginality_percent"
                />
                <EmptyValue v-else />
              </AppTableTd>
              <!-- Price type -->
              <AppTableTd nowrap>
                <div v-if="invoiceItem.custom && editable" class="form-wrapper is-small">
                  <select
                    @change="onInvoiceItemChoosePriceType(index)"
                    class="form-control"
                    v-model="invoiceItem.invoice_template_id"
                    required
                  >
                    <option :value="null" selected disabled hidden v-text="t('common.select')" />
                    <option
                      v-for="option in invoiceItemManualTemplates"
                      :key="option.id"
                      :value="option.id"
                      v-text="t(`invoice.price_type.${option.price_type}`)"
                    />
                  </select>
                </div>
                <span v-else v-text="t(`invoice.price_type.${invoiceItem.price_type}`)" />
              </AppTableTd>
              <!-- Description -->
              <AppTableTd>
                <FormTextarea v-if="editable" size="small" v-model="invoiceItem.description" />
                <span v-else v-text="invoiceItem.description" />
              </AppTableTd>
              <!-- Quick links -->
              <AppTableTd nowrap>
                <div class="d-flex align-items-center">
                  <RouterLink
                    v-tooltip.top="t('invoice.tooltip.report_link')"
                    class="ml-1"
                    :to="{
                      name: 'client.reported_time',
                      params: { uuid: invoice.client.uuid },
                      query: {
                        project: invoiceItem.project_id,
                        from: invoiceItem.custom
                          ? DateTime.local(invoice.time_period.year, invoice.time_period.month, 1)
                              .startOf('month')
                              .toFormat('yyyy-MM-dd')
                          : invoiceItem.invoiced_period.start,
                        to: invoiceItem.custom
                          ? DateTime.local(invoice.time_period.year, invoice.time_period.month, 1)
                              .endOf('month')
                              .toFormat('yyyy-MM-dd')
                          : invoiceItem.invoiced_period.end,
                      },
                    }"
                    target="_blank"
                    :class="{ disabled: !invoiceItem.project_id }"
                  >
                    <AppButton ghost size="small" light circle>
                      <FontIcon name="report" />
                    </AppButton>
                  </RouterLink>
                  <RouterLink
                    v-tooltip.top="t('invoice.tooltip.billing_link')"
                    class="ml-1"
                    :to="{
                      name: 'client.billing',
                      params: { uuid: invoice.client.uuid },
                    }"
                    target="_blank"
                    :class="{ disabled: !invoiceItem.project_id }"
                  >
                    <AppButton ghost size="small" light circle>
                      <FontIcon name="file-invoice" />
                    </AppButton>
                  </RouterLink>
                  <RouterLink
                    v-tooltip.top="t('invoice.tooltip.project_link')"
                    class="ml-1"
                    :to="{ name: 'projects.view', params: { uuid: invoice.client.uuid, id: invoiceItem.project_id } }"
                    target="_blank"
                    :class="{ disabled: !invoiceItem.project_id }"
                  >
                    <AppButton ghost size="small" light circle>
                      <FontIcon name="briefcase" />
                    </AppButton>
                  </RouterLink>
                </div>
              </AppTableTd>
              <!-- ERP Article -->
              <AppTableTd nowrap>
                <div class="form-wrapper is-small">
                  <select
                    @change="onInvoiceItemChooseArticle(index)"
                    v-model="invoiceItem.erp_article_id"
                    class="form-control"
                    required
                    v-if="editable"
                  >
                    <option :value="null" selected disabled hidden v-text="t('common.select')" />
                    <option
                      v-for="option in erpArticles"
                      :key="option.id"
                      :value="option.id"
                      v-text="option[`name_${form.language}`]"
                    />
                  </select>
                  <span v-if="!editable && invoiceItem.erp_article_id">
                    {{ getErpArticle(invoiceItem.erp_article_id)![`name_${form.language}`] }}
                  </span>
                </div>
              </AppTableTd>
              <!-- Quantity -->
              <AppTableTd class="text-right" nowrap>
                <div v-if="editable" class="form-wrapper is-small">
                  <input
                    type="number"
                    :value="invoiceItem.quantity"
                    @change="onQuantityChange(invoiceItem, $event)"
                    class="text-right form-control"
                    min="0"
                    step=".01"
                    required
                  />
                </div>
                <span v-else v-text="invoiceItem.quantity" />
              </AppTableTd>
              <!-- Unit -->
              <AppTableTd nowrap>
                {{ invoiceItem.unit }}
              </AppTableTd>
              <!-- Discount -->
              <AppTableTd v-if="discountable" class="text-right" nowrap>
                <div v-if="editable" class="form-wrapper is-small">
                  <input
                    type="number"
                    :value="invoiceItem.discount"
                    @change="onDiscountChange(invoiceItem, $event)"
                    class="text-right form-control"
                    max="100"
                    min="0"
                    step="1"
                    :disabled="
                      !invoiceItem.project_id ||
                      getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                    "
                  />
                </div>
                <span v-else v-text="invoiceItem.discount" />
              </AppTableTd>
              <!-- Price per unit -->
              <AppTableTd class="text-right" nowrap>
                <div v-if="editable" class="form-wrapper is-small">
                  <input
                    type="number"
                    :value="invoiceItem.price_per_unit"
                    @change="onPricePerUnitChange(invoiceItem, $event)"
                    class="text-right form-control"
                    :min="isInvoiceItemAdvance(invoiceItem) ? undefined : 0"
                    step=".01"
                  />
                </div>
                <span
                  v-else
                  v-text="n(calculateAmount(1, invoiceItem.price_per_unit, invoiceItem.discount), 'decimal')"
                />
              </AppTableTd>
              <!-- Total price -->
              <AppTableTd class="text-right" nowrap>
                <span
                  v-if="
                    getInvoicingAmount(invoiceItem) !== 0 &&
                    getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                  "
                  v-text="'-'"
                />
                {{ n(getInvoicingAmount(invoiceItem), 'decimal') }}
              </AppTableTd>
              <!-- Actions -->
              <AppTableTd class="text-right" nowrap>
                <template v-if="!isInvoiceItemAdvance(invoiceItem)">
                  <AppButton
                    v-if="editable && invoiceItem.postpone.quantity < invoiceItem.quantity"
                    v-tooltip="t('invoice.tooltip.postpone')"
                    @click.stop.prevent="onPostpone(invoiceItem)"
                    size="small"
                    light
                    circle
                    color="warning"
                    class="ml-2"
                  >
                    <FontIcon name="chevrons-right" />
                  </AppButton>
                  <AppButton
                    v-if="editable && invoiceItem.write_off.quantity < invoiceItem.quantity"
                    v-tooltip="t('invoice.tooltip.write_off')"
                    @click.stop.prevent="onWriteOff(invoiceItem)"
                    size="small"
                    light
                    circle
                    color="danger"
                    class="ml-2"
                  >
                    <FontIcon name="cash-banknote-off" />
                  </AppButton>
                  <AppButton
                    light
                    circle
                    size="small"
                    class="ml-2"
                    @click.stop.prevent="toggleInvoice(invoiceItem.uid)"
                    v-tooltip="t(`invoice.tooltip.${isExpandedInvoice(invoiceItem.uid) ? 'minimize' : 'expand'}`)"
                    :disabled="invoiceItem.write_off.quantity > 0 && invoiceItem.write_off.comment === ''"
                  >
                    <FontIcon
                      :name="isExpandedInvoice(invoiceItem.uid) ? 'chevron-up' : editable ? 'pencil' : 'chevron-down'"
                    />
                  </AppButton>
                </template>
                <AppButton
                  v-if="editable && invoiceItem.custom"
                  v-tooltip="t('common.delete')"
                  @click.stop.prevent="onDeleteCustomInvoiceItem(invoiceItem.uid)"
                  size="small"
                  light
                  circle
                  color="danger"
                  class="ml-2"
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
            <!-- Alerts -->
            <AppTableTr v-if="editable && showNegativeAdvanceBalanceWarning(invoiceItem)">
              <AppTableTd :colspan="discountable ? 12 : 11">
                <AppAlert type="warning">
                  <FontIcon name="alert-circle" />
                  {{ t('invoice.negative_advance') }}
                </AppAlert>
              </AppTableTd>
            </AppTableTr>
            <!-- Postpone -->
            <AppTableTr v-show="isExpandedInvoice(invoiceItem.uid)">
              <AppTableTd :colspan="discountable ? 5 : 4" />
              <AppTableTd class="text-right font-bold">{{ t('invoice.details.postpone_to_next_month') }}:</AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <div v-if="editable" class="has-icon form-wrapper is-small">
                  <i class="form-icon ti ti-numbers" />
                  <input
                    type="number"
                    :value="invoiceItem.postpone.quantity"
                    @change="onPostponeQuantityChange(invoiceItem, $event)"
                    class="text-right form-control"
                    min="0"
                    step=".01"
                    required
                  />
                </div>
                <span v-else v-text="invoiceItem.postpone.quantity" />
              </AppTableTd>
              <AppTableTd colspan="3" class="text-right" nowrap>
                <FormInput
                  v-if="editable"
                  size="small"
                  v-model="invoiceItem.postpone.comment"
                  :placeholder="t('common.comment')"
                />
                <span v-else v-text="invoiceItem.postpone.comment" />
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <span
                  v-if="
                    getPostponeAmount(invoiceItem) !== 0 &&
                    getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                  "
                  v-text="'-'"
                />
                {{ n(getPostponeAmount(invoiceItem), 'decimal') }}
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <AppButton
                  v-if="editable"
                  v-tooltip="t('common.cancel')"
                  @click.stop.prevent="discardPostpone(invoiceItem)"
                  size="small"
                  light
                  circle
                  color="danger"
                  :disabled="invoiceItem.postpone.quantity === 0"
                >
                  <FontIcon name="x" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
            <!-- Write Off -->
            <AppTableTr v-show="isExpandedInvoice(invoiceItem.uid)">
              <AppTableTd :colspan="discountable ? 5 : 4" />
              <AppTableTd class="text-right font-bold" nowrap> {{ t('invoice.details.write_off') }}:</AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <div v-if="editable" class="has-icon form-wrapper is-small">
                  <i class="form-icon ti ti-numbers" />
                  <input
                    type="number"
                    :value="invoiceItem.write_off.quantity"
                    @change="onWriteOffQuantityChange(invoiceItem, $event)"
                    class="text-right form-control"
                    min="0"
                    step=".01"
                    required
                  />
                </div>
                <span v-else v-text="invoiceItem.write_off.quantity" />
              </AppTableTd>
              <AppTableTd colspan="3" class="text-right" nowrap>
                <FormInput
                  v-if="editable"
                  size="small"
                  v-model="invoiceItem.write_off.comment"
                  :placeholder="`${t('common.comment')} ${invoiceItem.write_off.quantity > 0 ? '*' : ''}`"
                  :required="invoiceItem.write_off.quantity > 0"
                />
                <span v-else v-text="invoiceItem.write_off.comment" />
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <span
                  v-if="
                    getWriteOffAmount(invoiceItem) !== 0 &&
                    getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                  "
                  v-text="'-'"
                />
                {{ n(getWriteOffAmount(invoiceItem) ?? 0, 'decimal') }}
              </AppTableTd>
              <AppTableTd class="text-right" nowrap>
                <AppButton
                  v-if="editable"
                  v-tooltip="t('common.cancel')"
                  @click.stop.prevent="discardWriteOff(invoiceItem)"
                  size="small"
                  light
                  circle
                  color="danger"
                  :disabled="invoiceItem.write_off.quantity === 0"
                >
                  <FontIcon name="x" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
            <!-- Original -->
            <AppTableTr v-show="isExpandedInvoice(invoiceItem.uid)">
              <AppTableTd :colspan="discountable ? 5 : 4" />
              <AppTableTd class="text-right font-bold" nowrap> {{ t('invoice.details.total') }}:</AppTableTd>
              <AppTableTd class="text-right font-bold" nowrap>
                {{ getOriginalQuantity(invoiceItem) }}
              </AppTableTd>
              <AppTableTd colspan="3" />
              <AppTableTd class="text-right font-bold" nowrap>
                <strong
                  v-if="
                    getOriginalAmount(invoiceItem) !== 0 &&
                    getInvoiceItemManualTemplate(invoiceItem.invoice_template_id)?.is_inverted_amount
                  "
                  v-text="'-'"
                />
                {{ n(getOriginalAmount(invoiceItem), 'decimal') }}
              </AppTableTd>
              <AppTableTd />
            </AppTableTr>
          </template>
          <AppTableTr style="border-top-width: 5px">
            <AppTableTd :colspan="discountable ? 9 : 8">
              <h3 class="mb-0" v-text="t('invoice.details.total_invoice_amount')" />
            </AppTableTd>
            <AppTableTd colspan="2" class="text-right" nowrap>
              <h3 class="mb-0" v-text="n(totalPrice, 'currency')" />
            </AppTableTd>
            <AppTableTd class="text-right" nowrap>
              <AppButton
                v-if="editable"
                v-tooltip="t('common.add')"
                @click.stop.prevent="addCustomItem"
                light
                circle
                color="success"
              >
                <FontIcon name="plus" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppBox class="mt-3" shadow>
        <template v-if="editable">
          <AppBoxBody v-if="projectsWithoutExistedAdvanceBalance.length > 0">
            <AppAlert
              v-for="project in projectsWithoutExistedAdvanceBalance"
              :key="project.id"
              class="mb-2"
              type="danger"
            >
              <h3>
                <FontIcon name="alert-triangle" />
                {{ project.name }}
              </h3>
              <div v-html="t('invoice.no_positive_prepayment')" />
            </AppAlert>
          </AppBoxBody>
          <AppBoxBody v-if="projectsWithExistedAdvanceBalance.length > 0">
            <h2>{{ t('invoice.not_settled_prepayments') }}</h2>
            <AppAlert v-for="project in projectsWithExistedAdvanceBalance" :key="project.id" class="mb-2" type="info">
              <h3>
                <FontIcon name="info-square-rounded" />
                {{ project.name }}
              </h3>
              <div v-html="t('invoice.not_settled_prepayments_text', { value: project.advance_balance })" />
            </AppAlert>
          </AppBoxBody>
        </template>
        <AppBoxBody>
          <div class="form-group">
            <FormLabel class="font-bold" html-for="invoice_invoice_text">
              {{ t('invoice.attributes.invoice_text') }}
            </FormLabel>
            <FormTextarea v-if="editable" rows="4" id="invoice_invoice_text" v-model="form.invoice_text" />
            <span v-else v-text="form.invoice_text ?? t('common.empty')" />
          </div>
          <div class="form-group">
            <FormLabel class="font-bold" html-for="invoice_internal_note">
              {{ t('invoice.attributes.internal_note') }}
            </FormLabel>
            <FormTextarea v-if="editable" rows="4" id="invoice_internal_note" v-model="form.internal_note" />
            <span v-else v-text="form.internal_note ?? t('common.empty')" />
          </div>
          <AppAlert type="warning" v-if="!invoice.is_meta_exists">
            {{ t('invoice.customer_settings_incomplete.text') }}
            <RouterLink :to="{ name: 'client.settings', params: { uuid: invoice.client.uuid } }" target="_blank">
              {{ t('invoice.customer_settings_incomplete.link') }}
            </RouterLink>
          </AppAlert>
        </AppBoxBody>
      </AppBox>
      <div class="d-flex flex-nowrap mt-4">
        <RouterLink
          v-if="closestInvoices.prev"
          custom
          :to="{ name: 'invoices.edit', params: { id: closestInvoices.prev } }"
          v-slot="{ href, navigate }"
        >
          <a
            @click="navigate"
            :href="href"
            :class="{ disabled: submitLoader.isLoading.value || syncLoader.isLoading.value }"
          >
            <AppButton light :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value">
              {{ t('common.previous') }}
            </AppButton>
          </a>
        </RouterLink>
        <RouterLink
          v-if="closestInvoices.next"
          custom
          :to="{ name: 'invoices.edit', params: { id: closestInvoices.next } }"
          v-slot="{ href, navigate }"
        >
          <a
            @click="navigate"
            :href="href"
            class="ml-2"
            :class="{ disabled: submitLoader.isLoading.value || syncLoader.isLoading.value }"
          >
            <AppButton light :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value">
              {{ t('common.next') }}
            </AppButton>
          </a>
        </RouterLink>
        <AppButton
          @click.prevent="onFetchNotInvoiced"
          class="ml-auto"
          light
          :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value"
        >
          {{ t('invoice.details.fetch_not_invoiced_prices') }}
        </AppButton>
        <AppButton
          v-if="can('invoices.delete', invoice.internal_status)"
          v-tooltip.left="t('invoice.tooltip.delete')"
          @click.stop.prevent="onDelete"
          class="ml-2"
          color="danger"
          :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value"
        >
          {{ t('invoice.confirm.delete.action') }}
        </AppButton>
        <AppButton
          v-if="editable"
          class="ml-2"
          color="success"
          :loading="submitLoader.isLoading.value"
          :disabled="
            submitLoader.isLoading.value ||
            syncLoader.isLoading.value ||
            projectsWithoutExistedAdvanceBalance.length > 0
          "
        >
          {{ t('common.save') }}
        </AppButton>
        <AppButton
          @click.prevent="approveAndSync"
          class="ml-2"
          color="secondary"
          :loading="syncLoader.isLoading.value"
          v-if="isDraft"
          :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value || !invoice.is_meta_exists"
        >
          {{ t('invoice.details.approve_and_sync') }}
        </AppButton>
        <AppButton
          @click.prevent="approveAndSync"
          class="ml-2"
          color="secondary"
          :loading="syncLoader.isLoading.value"
          v-if="isActionRequired"
          :disabled="submitLoader.isLoading.value || syncLoader.isLoading.value"
        >
          {{ t('invoice.details.save_and_sync') }}
        </AppButton>
      </div>
    </form>
  </div>
  <LeaveConfirmModal :is-revealed="isRevealed" @confirm="confirm" @cancel="cancel" />
</template>
