export default {
  index: {
    title: 'Projekt',
    help: '',
    remove: 'Radera projekt',
    complete: 'Complete projekt',
    cancel: 'Stäng project',
    create_final_invoice: 'Skapa slutlig faktura',
    invoice_until_today: 'Fakturera fram till idag',
    rollover: 'Rulla projekt',
    reactivate: 'Reaktivera projekt',
    filters: {
      name_or_client: 'Projekt eller kund',
      name: 'Projekt',
      status: 'Projekt',
    },
    create_modal: {
      title: 'Ange vilken kund du vill skapa ett projekt för',
      name: 'Kund',
    },
  },
  create: {
    title: 'Planera nytt projekt för {name}',
    help: '',
  },
  edit: {
    title: 'Redigera projektet {name}',
    help: '',
  },

  empty: 'Inga projekt',

  step: {
    service: 'Tjänst',
    price: 'Pris',
    team: 'Team',
    planning: 'Uppgiftsplanering',
    activate: 'Sammanfattning',
  },

  // Attributes
  attributes: {
    name: 'Projektnamn',
    status: 'Status',
    client: 'Kund',
    user: 'Projektansvarig',
    service: 'Tjänst',
    financial_year: 'Räkenskapsår',
    start_date: 'Startdatum',
    end_date: 'Slutdatum',
    start_end_date: 'Start / slutdatum',
    start_and_end_date: 'Projektets start- och slutdatum',
    deadline_date: 'Leveransdatum',
    price_type: 'Pristyp',
    billing_frequency: 'Faktureringsintervall',
    annual_price: 'Årligt grundarvode',
    only_invoice_part_of_year: 'Fakturera endast del av år',
    number_of_months_for_invoices_per_part_of_year: 'Antal månader',
    estimating_price: 'Detta årets arvode',
    discount: 'Rabatt %',
    this_year_fee: 'Detta årets arvode',
    estimating_annual_fee: 'Beräknat årligt arvode',
    tasks_in_portal: 'Uppgifter i kundportal',
    other_team_members: 'Andra team-medlemmar',
  },
  placeholders: {
    name: 'Ange namn',
  },

  invoice_schedule: 'Fakturaschema',

  price: {
    fixed: 'Fast',
    hourly: 'Timarvode',
  },

  billing_frequency: {
    monthly: 'Månad',
    quarterly: 'Kvartal',
    'semi-annually': 'Halvår',
    annually: 'Årligen',
  },

  add_member_title: 'Välj en anställd för att lägga till', // Välj en anställd och roll i projektet för att lägga till
  team_members: 'Team-medlemmar',
  team_member_role: {
    team_member: 'Team-medlem',
    responsible: 'Projektansvarig',
  },
  project_role: 'Projektroll',

  planning_table: {
    summary: 'Sammanfattning',
    total_time: 'Summa tid',
    total_price: 'Summa arvode',
    margin: 'Marg%',
    this_project: 'Detta projektår',
    next_project: 'Nästa projektår',
    hour: 'h',
  },

  tasks_table: {
    name: 'Uppgift',
    frequency: 'Frekvens',
    week: 'Vecka',
    deadline: 'Deadline',
    estimated_time: 'Uppskattad tid',
    team_member: 'Team-medlem',
    charge_separately: 'Debitera extra',
    price_type: 'Pristyp',
    sub_label: 'Undernamn',
    amount: 'Belopp, SEK',
    discount: 'Rabatt, %',
    add_label_on_tasks: 'Lägg till extra etikett på uppgift',
    restore_plan_from_default: 'Återställ plan till standard',
  },

  task_details_table: {
    title: 'Uppgiftsdetaljer',
    task: 'Uppgift',
    frequency: 'Frekvens',
    week: 'Vecka',
    deadline: 'Deadline',
    est_time: 'Uppskattad tid',
    team_member: 'Team-medlem',
    price_info: 'Prisinfo',
    production_cost: 'Självkostnad',
    total_this_project: 'Summa detta projektår',
    total_next_project: 'Summa nästa projektår',
  },

  fee_and_invoicing_table: {
    title: 'Arvode & fakturering',
    price_type: 'Pristyp',
    invoice_frequency: 'Faktureringsintervall',
    discount: 'Rabatt',
  },

  invoices_table: {
    title: 'Fakturor',
    amount: 'Belopp',
    fee_type: 'Arvodestyp',
    month: 'Månad',
  },
  project_earnings_estimate_title: {
    title: 'Beräknad projektmarginal',
    first_year: 'Detta projektår',
    next_year: 'Nästa projektår',
    annual_price: 'Årsarvode',
    annual_hours: 'Årstimmar',
    revenue_hour: 'Arvode/timma',
    production_cost: 'Självkostnad',
    margin: 'Marg',
    margin_percent: 'Marg%',
  },

  statuses: {
    all: 'Alla',
    active: 'Aktiv',
    draft: 'Utkast',
    done: 'Klar',
    cancelled: 'Avbruten',
    deleted: 'Raderad',
  },

  active_project: 'Aktivera projekt',
  base_price: 'Grundarvode',
  separate_price: 'Separat extra arvode',
  estimated_hourly_fee: 'Timarvode (beräknat)',

  summaries: {
    invoiced: 'Fakturerat',
    scheduled: 'Schemalagt',
    fee_summary: 'Summa arvoden',
    difference: 'Differens',
  },

  // Tooltips
  tooltip: {
    edit: 'Redigera "{name}"',
    destroy: 'Radera "{name}"',
    view: 'Granska "{name}" projektet',
    plan: 'Planera "{name}" projektet',
    cancel: 'Avbryt "{name}" projektet',
    rollover: 'Rulla "{name}"',
    reactivate: 'Reaktivera "{name}"',
    copy_next_week: 'Kopiera vald vecka till nästa uppgift',
    copy_next_weeks: 'Kopiera vald vecka till alla framtida uppgifter',
    show_events: 'Visa event',
    hide_events: 'Dölj event',
    end_date:
      'Slutdatum avser det datum då projektet ska stängas och det inte ska rapporteras någon mer tid. Du kommer få en påminnelse att stänga projekt när detta datum inträffat.',
    deadline_date:
      'Leveransdatum avser den dag då kunden önskar rapport eller liknande, före projektets slutdatum. Detta kan användas t.ex. om kunden har ett specificerat datum för stämma, men ni vill hålla projektet öppet för slutdokumentation eller liknande.',
    base_plan_task_type: 'Denna uppgift skapas från Aktiverad projektplan',
    additional_task_type: 'Denna uppgift lades till efter att projektet planerades',
    service_task_type:
      'Denna uppgift är genererad av tjänstens uppgiftsmall. Klicka reaktivera för att inkludera i projektplanen',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Radera projekt',
      text: 'Är du säker på att du vill radera <strong>{name}</strong>?',
    },
    restore_default_tasks: {
      title: 'Återställ till standarduppgifter',
      text: 'Är du säker på att du vill återställa till standarduppgifter?',
    },
  },

  messages: {
    the_fee_summary_does_not_match: {
      title: 'Summa arvoden avviker mot angivet projektarvode.',
      text: 'Uppdatera schemalagda arvoden eller projektets arvode?',
    },
    project_dates_updated: {
      title: 'Projektdatum är uppdaterade.',
      text: 'Du kan behöva ändra schemalagda fakturor och tidsplan för uppgifter. Fortsätt med planering av projektet för att göra eventuellt nödvändiga följdändringar.',
    },
    project_has_been_activated: {
      text: 'Projektet har skapats/uppdaterats och uppgifter har schemalagts.',
    },
    project_should_be_reactivated_tasks: {
      text: 'NOTERA! Du har uppdaterat projektets uppgifter. Du behöver gå till nästa steg och Aktivera den uppdaterade planen för att det ska uppdateras teamets planering.',
    },
    project_should_be_reactivated_billing_plans: {
      text: 'NOTERA! Du har uppdaterat fakturaplan. Du behöver gå till nästa steg och Aktivera den uppdaterade planen för att det ska uppdateras teamets planering.',
    },
    tasks_were_copied: {
      text: 'Dessa uppgifter har kopierats från föregående projekt. Eventuella uppgifter som lades till i föregående projekt utöver planeringen är nedan listade, men satta som inaktiva och visas i grå text. Detsamma gäller om byrån har lagt till nya standarduppgifter på denna tjänst.</br>Du kan reaktivera dessa uppgifter om du vill att dessa ska ingå i denna projektplanen.',
    },
    new_tasks_in_service: {
      title: 'Nya uppgiftsmallar som lagts till som standard för denna tjänst.',
      text: 'Denna uppgift är genererad av tjänstens uppgiftsmall. Klicka reaktivera för att inkludera i projektplanen.',
    },
    deleted_tasks_from_service: {
      text: 'Den här uppgiften ingår inte i standaduppgifterna för den här tjänsten. Klicka på reaktivera för att ta med i projektplanen ändå.',
    },
  },

  view: {
    general: 'General',
    overview: 'Översikt',
    status: 'Status',
    deadline: 'Deadline',
    tasks: 'Uppgifter',
    comment: 'Kommentar',
    there_are_unfinished_tasks:
      'Det finns uppgifter som inte har slutförts. Kontrollera dessa innan du stänger projektet.',
    there_are_no_unfinished_tasks: 'Alla uppgifter är klara',
    cancel_all_open_tasks: 'Avbryt alla öppna uppgifter',
    all_tasks_must_be_completed_to_close_project:
      'Alla projektuppgifter måste vara klarmarkerade för att avsluta projektet',
    checkpoints: 'Checkpoints',
  },
  details: {
    open_checkpoints_overview: 'Öppna översikt för Checkpoints',
    time_summary: 'Tidsummering',
    open_reported_time: 'Öppna Rapporterad tid',
    financial_summary: 'Finansiell översikt',
    open_invoice_overview: 'Öppna faktureringsöversikt',
    planned: 'Planerad',
    estimated: 'Estimerad',
    actual: 'Faktiskt',
    diff: 'Diff',
    base_plan: 'Grundplan',
    additional: 'Tillägg',
    total: 'Total',
    extra_time_billable: 'Mertid - debiterbar',
    extra_time_not_billable: 'Mertid - ej debiterbar',
    average_revenue_per_hour_planned: 'Intäkt per timma (Planerad)',
    average_revenue_per_hour_actual: 'Intäkt per timma (Faktiskt)',
    invoiced: 'Fakturerad tid',
    reported_time_cost: 'Kostnad för tid',
    prel_margin: 'Prel.marginal',
    prel_margin_percentage: 'Prel.marginal %',
    billed_expenses: 'Fakturerade utlägg',
    expenses: 'Utlägg',
    planned_estimated_invoices: 'Planerad/Estimerade fakturor',
    estimated_remaining_time_cost: 'Estimerad återstående tidskostnad',
    total_margin: 'Total marginal',
    total_margin_percentage: 'Total marginal %',
    tooltips: {
      invoiced: 'Fakturerat belopp för vårt arbete',
      reported_time_cost: 'Kostnad för resursernas tid beräknat enligt självkostnad',
      prel_margin: 'Marginalen på eget arbete (byråintäkt)',
      billed_expenses: 'Vidarefakturerade inköp/utlägg',
      planned_estimated_invoices:
        'Beräknad återstående fakturering baserat på faktureringschema och planerad framtida tid för arbete som debiteras på löpande räkning',
      estimated_remaining_time_cost:
        'Återstående kostnad för resursernas framtida planerade tid, beräknat enligt självkostnad',
      total_margin: 'Projektmarginalen inklusive externa kostnader',
      time_summary: {
        planned: 'Planerad tid enligt ursprunglig plan, fram till idag',
        estimated:
          'Beräknad total tid på projektet baserat på projektplan och eventuella ytterligare timmar rapporterade på projektet, fram till idag',
        actual: 'Rapporterade timmar på projektet, fram till idag',
      },
      financial_summary: {
        planned: 'Värden baserade på ursprunglig projektplan, fram till idag',
        actual: 'Faktiskt utfall, fram till idag',
      },
    },
  },

  cancel_modal: {
    title: 'Stäng projekt {name}',
    message:
      'Genom att klicka på "Bekräfta", stängs projekt och blir inaktiverat. Du kan reaktivera det senare om du behöver.',
    warning:
      'Projektet har uppgifter som inte påbörjats eller slutförts. Genom att stänga projektet, kommer påbörjade uppgifter klarmarkeras, och ej startade uppgifter kommer tas bort från uppgiftslistan och går ej att rapportera tid på.',
    start_new_project: 'Starta planering av ett nytt projekt genom att kopiera planering för projekt {name}',
    increase_prices: 'Räkna upp eventuella fasta priser automatiskt',
    percentage_price_increase: 'Ange procentuell prisökning för fasta priser',
    //
    final_invoice: 'Skapa slutlig faktura (Fakturautkastet öppnas i en ny flik efter att du klickat på Bekräfta)',
    new_project_plan: 'Starta planering av ett nytt projekt genom att kopiera planering för projekt {name}',
    auto_price_increase: 'Räkna upp eventuella fasta priser automatiskt',
    price_increase_percent: 'Ange procentuell prisökning för fasta priser',
  },
  reactivate_modal: {
    title: 'Reaktivera projekt {name}',
    message: '',
  },
  rollover_modal: {
    title: 'Rulla projekt {name}',
  },
};
