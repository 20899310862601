<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { AppButton, AppTableTd, AppTableTr, FontIcon } from '@/components';
import { IProjectTaskResource } from '@/types/ProjectTask';
import { IServiceTaskResource } from '@/types/Service';
import useTime from '@/composables/useTime';

type AdditionalTask = { task: IProjectTaskResource; type: 'additional' };
type InactiveBaseTask = { task: IProjectTaskResource; type: 'inactive_base' };
type ServiceTask = { task: IServiceTaskResource; type: 'service_task' };

const { task, type, isSubLabelEnabled } = defineProps<
  (AdditionalTask | InactiveBaseTask | ServiceTask) & { isSubLabelEnabled: boolean }
>();

const emit = defineEmits<{
  activateInactiveTask: [serviceTask: ServiceTask];
}>();

const { t } = useI18n({ useScope: 'global' });
const { convertMinutesToTime } = useTime();

const isAdditional = computed(() => type === 'additional');
const isInactiveBase = computed(() => type === 'inactive_base');
const isServiceTask = computed(() => type === 'service_task');
</script>

<template>
  <AppTableTr class="text-neutral-400">
    <AppTableTd :colspan="isSubLabelEnabled ? 2 : 1">
      <div class="d-flex align-items-center">
        <i class="ti ti-grip-vertical text-2 flex-shrink-0 pr-2" style="opacity: 0" />
        <i
          class="ti text-2 flex-shrink-0 pr-2 ti-calendar-plus text-success-500"
          v-if="isAdditional"
          v-tooltip.right="t(`project.tooltip.additional_task_type`)"
        />
        <i
          class="ti text-2 flex-shrink-0 pr-2 ti-calendar-event text-secondary-500"
          v-if="isInactiveBase"
          v-tooltip.right="t(`project.tooltip.base_plan_task_type`)"
        />
        <i
          class="ti text-2 flex-shrink-0 pr-2 ti-calendar-cog text-warning-500"
          v-if="isServiceTask"
          v-tooltip.right="t(`project.tooltip.service_task_type`)"
        />
        {{ task.name }}
      </div>
    </AppTableTd>
    <AppTableTd>
      <span
        v-if="isAdditional || isInactiveBase"
        v-text="t(`task.frequency.${task.serviceTask.default_frequency ?? 'once'}`)"
      />
      <span v-if="isServiceTask" v-text="t(`task.frequency.${task.default_frequency ?? 'once'}`)" />
    </AppTableTd>
    <AppTableTd colspan="2" />
    <AppTableTd>
      <span v-if="isAdditional || isInactiveBase" v-text="convertMinutesToTime(task.serviceTask.default_time_budget)" />
      <span v-if="isServiceTask" v-text="convertMinutesToTime(task.default_time_budget)" />
    </AppTableTd>
    <AppTableTd>
      <span v-if="isAdditional || isInactiveBase" v-text="t(`task.role.${task.serviceTask.default_role}`)" />
      <span v-if="isServiceTask" v-text="t(`task.role.${task.default_role}`)" />
    </AppTableTd>
    <AppTableTd :colspan="3" />
    <AppTableTd>
      <div class="d-flex gap-2 justify-content-end">
        <AppButton
          v-tooltip.left="t('common.activate')"
          @click.stop.prevent="emit('activateInactiveTask')"
          color="success"
          size="small"
          light
          circle
        >
          <FontIcon name="plus" />
        </AppButton>
      </div>
    </AppTableTd>
  </AppTableTr>
</template>
